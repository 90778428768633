import imgProxy from "~/utils/imgproxy";
import { GdprPermissionRequest } from "~/components/shared/GDPR/GdprPermissionRequest";
import { Show } from "solid-js";
import { useDidomiContext } from "~/contexts/DidomiContext";
import { Portal } from "solid-js/web";

import "~/components/shared/WysiwygComponents.css";
import { urlRs } from "~/utils/url";

export default function SeoText() {
  const [, { hasGivenConsentForUrl }] = useDidomiContext();

  return (
    <>
      <section class="block-seo" data-test="block-seo">
        <div class="button">
          <a
            href={urlRs(
              "landings",
              "/votre-futur-bien/par-opportunite/nos-programmes-access/",
            )}
            class="btn"
            data-test="btn-landing"
          >
            Découvrez toutes nos résidences Cogedim Access
          </a>
        </div>
        <h2>
          Vous pensiez que devenir propriétaire était un rêve
          inaccessible&nbsp;?
        </h2>
        <div class="row">
          <div class="text-part">
            <p>
              Cogedim bouscule les codes de l’immobilier neuf pour le rendre
              abordable à tous&nbsp;! Parce que nous défendons l’idée que
              devenir propriétaire est un projet de vie qui doit être à la
              portée de chacun, nous avons créé COGEDIM ACCESS, une solution
              sur-mesure pour financer votre acquisition.
            </p>
            <p>
              Avec COGEDIM ACCESS, l’achat immobilier devient simple et
              accessible&nbsp;: pas d’apport&nbsp;? On vous accompagne&nbsp;!
              Des mensualités compatibles avec votre budget logement
              actuel&nbsp;? C’est possible&nbsp;! Un logement qui vous
              ressemble&nbsp;? Les atouts du neuf et la qualité COGEDIM en plus.
            </p>
            <p>
              N’attendez plus, COGEDIM ACCESS vous ouvre les portes de la
              propriété.
            </p>
          </div>
          <picture class="visual">
            <img
              src={imgProxy(
                "/images/nouveau-neuf/access-visual-medium.jpg",
                `size:400:340/resizing_type:fill`,
              )}
              alt=""
              height="340"
              width="400"
              loading="lazy"
            />
          </picture>
        </div>
        <div class="row">
          <div class="strengths" data-test="strengths">
            <div class="strength" data-test="strength">
              <img src="/images/pictos-strength/pictomensualites.png" alt="" />{" "}
              <span>
                Une mensualité proche d’un loyer<sup>(1)</sup>
              </span>
            </div>
            <div class="strength" data-test="strength">
              <img src="/images/pictos-strength/pictomensualites.png" alt="" />{" "}
              <span>
                500 € à la réservation<sup>(1)</sup>
              </span>
            </div>
            <div class="strength" data-test="strength">
              <img src="/images/pictos-strength/pictozeroeuro.png" alt="" />{" "}
              <span>
                Aucun frais avant d’emménager<sup>(1)</sup>
              </span>
            </div>
          </div>
        </div>

        <h2>L’immobilier neuf enfin accessible à tous</h2>
        <div class="row reversed">
          <div class="text-part">
            <p>
              <strong>
                80% des Français rêvent de devenir propriétaires, et pour 61%
                d'entre eux, c’est une priorité.
              </strong>{" "}
              Mais pourtant, le pouvoir d’achat immobilier des classes moyennes
              a fondu de moitié ces 50 dernières années... Pas facile de
              concrétiser ses rêves immobiliers&nbsp;!
            </p>
            <p>
              COGEDIM ACCESS a été créé pour enfin changer la donne.
              <br />
              Oubliez les principaux freins à l’acquisition :
            </p>
            <ul>
              <li>
                <strong>Plus besoin d'apport :</strong> on s’occupe de tout,
                même des frais de notaire&nbsp;!
              </li>
              <li>
                <strong>Rien à payer avant d’emménager :</strong> vous ne
                commencez à rembourser votre crédit qu’à la remise des clés.
              </li>
              <li>
                <strong>Des mensualités adaptées&nbsp;:</strong> des logements
                au prix juste et un financement adapté à votre budget.
              </li>
            </ul>
            <p>
              <strong>
                Avec COGEDIM ACCESS, devenez propriétaire en toute simplicité et
                sans prise de tête&nbsp;!
              </strong>
            </p>
          </div>
          <div class="video-container">
            <Show
              when={hasGivenConsentForUrl!(
                "https://www.youtube.com/embed/CvZW7AbFssU",
              )}
              fallback={
                <div class="comp-embed-container">
                  <GdprPermissionRequest
                    string={"https://www.youtube.com/embed/CvZW7AbFssU"}
                  />
                </div>
              }
            >
              <div class="comp-video">
                <iframe
                  src="https://www.youtube.com/embed/CvZW7AbFssU"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </Show>
          </div>
        </div>

        <h2>Nos vidéos témoignages</h2>
        <div class="row">
          <div class="short-container">
            <Show
              when={hasGivenConsentForUrl!(
                "https://www.youtube.com/embed/NltZsJR4_1I",
              )}
              fallback={
                <div class="comp-embed-container">
                  <GdprPermissionRequest
                    string={"https://www.youtube.com/embed/NltZsJR4_1I"}
                  />
                </div>
              }
            >
              <iframe
                src="https://www.youtube.com/embed/NltZsJR4_1I"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </Show>
          </div>
          <div class="short-container">
            <Show
              when={hasGivenConsentForUrl!(
                "https://www.youtube.com/embed/EM2VLCzDlRY",
              )}
              fallback={
                <div class="comp-embed-container">
                  <GdprPermissionRequest
                    string={"https://www.youtube.com/embed/EM2VLCzDlRY"}
                  />
                </div>
              }
            >
              <iframe
                src="https://www.youtube.com/embed/EM2VLCzDlRY"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </Show>
          </div>
        </div>
      </section>

      <Portal mount={document.getElementById("legals-offer-receiver")!}>
        <p class="legals">
          (1) Exemple de mensualités pour une acquisition, en résidence
          principale, du Lot 10025, appartement 2 pièces alcôve situé 2ème étage
          de la résidence Rive Nature à Villeneuve-la-Garenne, d’une surface
          habitable de 49,59 m² avec un balcon de 9,48 m² et 1 place de
          stationnement. Appartement vendu en l’état futur d’achèvement en TVA
          réduite à 5,5 % au prix de 229 500,00€. Financement sans apport
          personnel réalisé grâce au PTZ, un PAS et à des prêts consentis auprès
          des partenaires bancaires du groupe ALTAREA par l’intermédiaire
          d’Altarea Solution Financement. TVA réduite à 5,5 % (acquisition pour
          10 ans minimum), PTZ et PAS, réservés aux primo-accédants pour l’achat
          d’un logement en résidence principale sont soumis à conditions de
          revenus. Montant total des crédits : 229 500 €. Durée totale des
          crédits : 25 ans. TAEG global estimé (y/c assurance obligatoire) de
          1,68 %. Coût total du crédit (y/c assurance) : 51 711,11 €. Conditions
          détaillées sur le site cogedim.com. Exemple communiqué, sans valeur
          contractuelle, établi sur la base de taux moyens du marché constatés
          au 4/04/2024 pour diverses situations comparables. Altarea Solution
          Financement est une société de courtage en crédit immobilier et en
          assurances. Siège social : 87 Rue de Richelieu 75002 Paris. SAS au
          capital de 10 000 € immatriculée au R.C.S Paris sous le N°504 638 784
          et au registre de l’Orias (www.orias.fr) sous le N°10053430.
          L’emprunteur dispose d’un délai légal de réflexion de 10 jours.
          Lorsque la vente est subordonnée à l’obtention d’un prêt et si
          celui-ci n’est pas obtenu, le vendeur doit rembourser les sommes
          versées par l’acquéreur. Le versement des mensualités, hors
          assurances, débutera à la date de livraison de l’appartement. Un
          crédit vous engage et doit être remboursé. Vérifiez vos capacités de
          remboursement avant de vous engager. Sous réserve d'acceptation de
          votre dossier par nos partenaires bancaires. Cogedim SAS, 87, rue de
          Richelieu 75002 Paris, RCS PARIS n° 054 500 814 - SIRET 05 450 081 400
          063. Conditions complètes consultables{" "}
          <a
            href="https://www.cogedim.net/animations/brochure/Cogedim_Access_Details_Simulation.pdf"
            target="_blank"
            class="ext"
            rel="noopener noreferrer nofollow"
          >
            ici.
          </a>
        </p>
      </Portal>
    </>
  );
}
