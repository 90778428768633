import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

export default function Header() {
  const settings = useDrupalSettingsContext();

  return (
    <>
      <section class="heading" data-test="header">
        <img
          src={settings.marketing_segments_terms.access.field_logo_main}
          alt="Cogedim Access"
          height="56"
          width="200"
        />
        <h1>
          <strong>Avec COGEDIM ACCESS</strong>, pour la première fois vous
          pouvez devenir propriétaire.
        </h1>

        <p class="catchline" data-test="catchline">
          Indiquez le budget que vous consacrez chaque mois pour vous loger et
          découvrez notre sélection d'appartements et maisons.
        </p>
      </section>
    </>
  );
}
